:root {
    --color-bg1: #fafafa;
    --color-bg2: #fafafa;
    --color1: 250, 219, 68; /* RGB */
    --color2: 231, 25, 61;  /* RGB */
    --color3: 100, 100, 255; /* RGB without alpha */
    --color4: 50, 160, 220; /* RGB */
    --color5: 80, 47, 122;  /* RGB */
    --color-interactive: 140, 100, 255; /* RGB */
    --circle-size: 80%;
    --blending: hard-light;
  }
  
  .noise {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    opacity: 0.1;
  }
  
  .content {
    position: relative;
    z-index: 2;
    text-shadow: -3px 0px 2px rgba(0, 0, 0, 0.1);
  }
  
  @keyframes moveInCircle {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes moveVertical {
    0% {
      transform: translateY(-50%);
    }
    50% {
      transform: translateY(50%);
    }
    100% {
      transform: translateY(-50%);
    }
  }
  
  @keyframes moveHorizontal {
    0% {
      transform: translateX(-50%) translateY(-10%);
    }
    50% {
      transform: translateX(50%) translateY(10%);
    }
    100% {
      transform: translateX(-50%) translateY(-10%);
    }
  }
  
  .video_art .animation-bg {
      position: absolute;
      top: 0;
      bottom: 0;
      object-fit: cover;
      left: 0;
      opacity: 40%;
      right: 0;
      width: 100%;
      height: 100%;
  }
  
  .gradient-bg {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    background: linear-gradient(40deg, var(--color-bg1), var(--color-bg2));
  }
  
  .gradient-bg .svgBlur {
    display: none;
  }
  
  .gradient-bg .noiseBg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    mix-blend-mode: soft-light;
    opacity: 0.3;
  }
  
  .gradient-bg .gradients-container {
    filter: url(#goo) blur(40px);
    width: 100%;
    height: 100%;
  }
  
  .gradient-bg .g1 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color1), 1) 0%, rgba(var(--color1), 0) 50%) no-repeat; /* Added alpha to rgba */
    mix-blend-mode: var(--blending);
    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);
    transform-origin: center center;
    animation: moveVertical 30s ease infinite;
    opacity: 1;
  }
  
  .gradient-bg .g2 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color2), 1) 0%, rgba(var(--color2), 0) 50%) no-repeat; /* Added alpha to rgba */
    mix-blend-mode: var(--blending);
    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);
    transform-origin: calc(50% - 400px);
    animation: moveInCircle 20s reverse infinite;
    opacity: 0.5;
  }
  
  .gradient-bg .g3 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color3), 0.6) 0%, rgba(var(--color3), 0) 50%) no-repeat; /* Added alpha to rgba */
    mix-blend-mode: var(--blending);
    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2 + 200px);
    left: calc(50% - var(--circle-size) / 2 - 500px);
    transform-origin: calc(50% + 400px);
    animation: moveInCircle 40s linear infinite;
    opacity: 0.5;
  }
  
  .gradient-bg .g4 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color4), 1) 0%, rgba(var(--color4), 0) 50%) no-repeat; /* Added alpha to rgba */
    mix-blend-mode: var(--blending);
    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);
    transform-origin: calc(50% - 200px);
    animation: moveHorizontal 40s ease infinite;
    opacity: 0.7;
  }
  
  .gradient-bg .g5 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color5), 1) 0%, rgba(var(--color5), 0) 50%) no-repeat; /* Added alpha to rgba */
    mix-blend-mode: var(--blending);
    width: calc(var(--circle-size) * 2);
    height: calc(var(--circle-size) * 2);
    top: calc(50% - var(--circle-size));
    left: calc(50% - var(--circle-size));
    transform-origin: calc(50% - 800px) calc(50% + 200px);
    animation: moveInCircle 20s ease infinite;
    opacity: 0.5;
  }
  
  .gradient-bg .interactive {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color-interactive), 1) 0%, rgba(var(--color-interactive), 0) 50%) no-repeat; /* Added alpha to rgba */
    mix-blend-mode: var(--blending);
    width: 100%;
    height: 100%;
    top: -50%;
    left: -50%;
    opacity: 0.7;
  }
  