.outline-btn{
    border: 0.5px solid rgba(0, 0, 0, 0.20)!important;
}
.outline-btn:hover{
    border:  0.5px solid transparent!important;
background: linear-gradient(90deg, rgba(249, 202, 63, 0.20) 0%, rgba(230, 38, 110, 0.20) 36.5%, rgba(109, 103, 167, 0.20) 70%, rgba(51, 171, 145, 0.20) 100%);
/* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25); */
}
.btn-light-success {
    color: #101111;
    background-color: #C9EBE4;
    border-color: #C9EBE4;
    box-shadow: none;
}
.btn-light-success:hover,.btn-light-success:focus{
    color: #fff;
    background-color: #000;
    border-color: #000;
    box-shadow: none;
}
.x-small{
    font-size: 14px;
}
.z-1{
    z-index: 1!important;
}
.z-2{
    z-index: 2!important;
}
.z-3{
    z-index: 3!important;
}
.z-4{
    z-index: 4!important;
}
/* ---------- */
.opacity-100 {
    opacity: 1!important;
}
.opacity-75 {
    opacity: .75!important;
}
.opacity-50 {
    opacity: .5!important;
}
.opacity-25 {
    opacity: .25!important;
}
.navbar-light .navbar-toggler {
    color: rgba(0,0,0,.5);
    border-color: transparent;
}
.mn-auto{
    min-height: auto!important;
}