html{
overflow-x: hidden;
}



.rarity-icon {
  height: 25px !important;
  width: 30px !important;
  /* float: right!important; */
  position: absolute;
  right: 0px;
  /* -webkit-filter: brightness(0) invert(1)!important; */
  /* filter: brightness(0) invert(1)!important; */
  object-fit: contain !important;
}

.login-wrap {
  position: relative;
  background: transparent;
  border-radius: 5px;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: 0px 10px 34px -15px rgb(144 116 87);
}


.login-wrap .img {
  width: 83px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 9px;
}

.login-wrap h3 {
  font-weight: 400;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.login-wrap p {
  color: #000;
}

.login-wrap a {
  color: #000;
}

.form-group .icon {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 20px;
  height: 48px;
  background: transparent;
  font-size: 18px;
}

.form-group .icon span {
  color: #fff;
}

.form-group .icon span {
  color: #fff;
}

.main_menu {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  /* box-shadow: 0 0 15px rgb(240 233 233 / 79%); */
  z-index: 999;
}

.form-check-label {
  margin-bottom: 0;
  color: #fff;
}


#particles-js2 {
  width: 100%;
  height: 100%;
  position: fixed;
  /* background-image: linear-gradient( 
  70deg
  , #182231 0%, #182231 25%, #182231 35%, #05090d 76%, #05090d 75%, #182231 100% ); */
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

#particles-js {
  width: 100%;
  height: 100%;
  background-color: #b61924;
  /* background-image: url(''); */
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.banner-element-eight {
  position: absolute;
  right: 20px;
  bottom: 70%;
  -webkit-animation: lineThree 8s infinite linear;
  animation: lineThree 8s infinite linear;
}

.banner-element-nine {
  position: absolute;
  right: 40px;
  top: 10%;
  -webkit-animation: lineThree 10s infinite linear;
  animation: lineThree 10s infinite linear;
}

.banner-element-twelve {
  position: absolute;
  left: 20%;
  top: 0;
  -webkit-animation: lineThree 8s infinite linear;
  animation: lineThree 8s infinite linear;
}

.banner-element-thirteen {
  position: absolute;
  left: 20%;
  top: 10%;
  -webkit-animation: lineThree 10s infinite linear;
  animation: lineThree 10s infinite linear;
}

.banner-element-fourteen {
  position: absolute;
  left: 50%;
  top: 10%;
  -webkit-animation: lineThree 10s infinite linear;
  animation: lineThree 10s infinite linear;
}

.banner-element-fifteen {
  position: absolute;
  left: 60%;
  top: 5%;
  -webkit-animation: lineThree 8s infinite linear;
  animation: lineThree 8s infinite linear;
}

.banner-element-sixteen {
  position: absolute;
  left: 65%;
  top: 5%;
  -webkit-animation: lineThree 8s infinite linear;
  animation: lineThree 8s infinite linear;
}

.banner-element-seventeen {
  position: absolute;
  right: 0;
  bottom: 50%;
  -webkit-animation: lineThree 8s infinite linear;
  animation: lineThree 8s infinite linear;
}

.banner-element-eightteen {
  position: absolute;
  right: 0;
  bottom: 20%;
  -webkit-animation: lineThree 8s infinite linear;
  animation: lineThree 8s infinite linear;
}

/*@keyframes swing {
  0%, 100% { transform: rotate(-30deg); }
  20% {transform:scale(.95);}
  50% { transform: rotate(30deg); }
  80% {transform:scale(.95);}
  }
  #sweetland {
  animation: swing 2s infinite ease-in-out;
  }*/
img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

.event {
  margin: 50px auto;
  text-align: center;
}

@-webkit-keyframes lineThree {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }

  20% {
    -webkit-transform: translate3d(-70px, 80px, 0);
    transform: translate3d(-70px, 80px, 0);
    opacity: 1;
  }

  90% {
    opacity: 1;
    -webkit-transform: translate3d(-300px, 310px, 0);
    transform: translate3d(-300px, 310px, 0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(-360px, 380px, 0);
    transform: translate3d(-360px, 380px, 0);
  }
}

@keyframes lineThree {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }

  20% {
    -webkit-transform: translate3d(-70px, 80px, 0);
    transform: translate3d(-70px, 80px, 0);
    opacity: 1;
  }

  90% {
    opacity: 1;
    -webkit-transform: translate3d(-300px, 310px, 0);
    transform: translate3d(-300px, 310px, 0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(-360px, 380px, 0);
    transform: translate3d(-360px, 380px, 0);
  }
}

.first_stone {
  /* background-image: url('./../public/img/first_stone.png'); */
  /* background-image: url('./../img/first_stone.png'); */
  background-size: 100% 100%;
  width: 200px;
  margin-top: -34px;
  color: #fff;
  height: 147px;
  background-repeat: no-repeat;
  /* background: transparent; */
}

.second_stone {
  /* background-image: url('./../public/img/second_stone.png'); */
  background-size: 100% 100%;
  width: 200px;
  color: #fff;
  margin-top: -20px;
  height: 147px;
  background-repeat: no-repeat;
  /* background: transparent; */
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}

.cardcontainer {
  animation: zoominoutsinglefeatured 10s infinite;
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}

.img_one {
  animation: zoominoutsinglefeatured 10s infinite;
  object-fit: contain;
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}

.img_two {
  animation: zoominoutsinglefeatured 5s infinite;
}

.pattern-layer {
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  height: 363px;
  z-index: 1;
  background-repeat: repeat-x;
}

/* ----- - -Market  Place----- */

* {
  /* margin: -1px; */
  /* padding: 3px; */
  box-sizing: border-box;
}

body {
  font-family: Catamaran;
}


/* .container {
  max-width: 1300px;
  margin: auto;
  padding-left: 25px;
  padding-right: 25px;
} */

/* .row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
  } */
header.main_menu.single_page_menu {
  background: transparent;
  padding: 20px 0px;
}

.col-2 {
  flex-basis: 50%;
  min-width: 300px;
}

.col-2 img {
  max-width: 100%;
  padding: 50px 0;
}

.col-2 h1 {
  font-size: 50px;
  line-height: 60px;
  margin: 25px 0;
}

.btn {
  display: inline-block;
  /* background:-webkit-linear-gradient(right, #31271d 0%, #816e4e, #31271d 100%);    */
  /* #0b6dc4 51% */
  padding: 8px 15px;
  /* margin: 30px 0; */
  border-radius: 30px;
}

.btn:hover {
  /* background:-webkit-linear-gradient(right, #091a1f 0%, #0b6dc4 51%, #010a0c 100%); */
}

.header {
  background: radial-gradient(#fff, #ffd6d6);
}

.header .row {
  margin-top: 70px;
}

.categories {
  margin: 70px 0;
}

/* .col-3 {
  flex-basis: 30%;
  min-width: 250px;
  margin-bottom: 30px;
} */

.col-3 img {
  width: 100%;
}

.small-container {
  max-width: 1190px;
  margin: auto;
  padding-left: 25px;
  padding-right: 25px;
}

.col-4 {
  border: 1px solid #fff;
  flex-basis: 25%;
  padding: 0px;
  min-width: 270px;
  margin-bottom: 50px;
  transition: transform 0.5s;
}

.col-4 img {
  width: 100%;
}

.title {
  text-align: center;
  margin: 0 auto 80px;
  position: relative;
  line-height: 60px;
  color: #555;
}

.title::after {
  content: "";
  background: #816e4e;
  width: 80px;
  height: 5px;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

h4 {
  color: #555;
  font-weight: normal;
}

.col-4 p {
  font-size: 14px;
  margin: 0px;
  text-align: center;
}

hr {
  margin: 0px;
  border-top: 0.5px solid #ffffff38;
}

.img-owner span {
  color: #fff;
  font-size: 12px;
}

img.user_img {
  margin-right: 10px;
}

.rating .fas {
  color: #00C9FF;
}

.rating .far {
  color: #00C9FF;
}

.col-4:hover {
  transform: translateY(-5px);
}

/* Offer */

.offer {
  background: radial-gradient(#fff, #ffd6d6);
  margin-top: 80px;
  padding: 30px 0;
}

.col-2 .offer-img {
  padding: 50px;
}

small {
  color: #000;
  opacity: 0.5;
  font-weight: 400;
}

/* testimonial */

.testimonial {
  padding-top: 100px;
}

.testimonial .col-3 {
  text-align: center;
  padding: 40px 20px;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.5s;
}

.testimonial .col-3 img {
  width: 100px;
  margin-top: 20px;
  border-radius: 50%;
}

.testimonial .col-3:hover {
  transform: translateY(-10px);
}

.fa-quote-left {
  font-size: 34px;
  color: #00C9FF;
}

.col-3 p {
  font-size: 14px;
  margin: 12px 0;
  color: #777777;
}

.testimonial .col-3 h3 {
  font-weight: 600;
  color: #555;
  font-size: 16px;
}

.brands {
  margin: 100px auto;
}

.col-5 {
  width: 160px;
}

.col-5 img {
  width: 100%;
  cursor: pointer;
  filter: grayscale(100%);
}

.col-5 img:hover {
  width: 100%;
  cursor: pointer;
  filter: grayscale(0);
}

/* footer */

.footer {
  background: #000;
  color: #8a8a8a;
  font-size: 14px;
  padding: 60px 0 20px;
}

.footer p {
  color: #8a8a8a;
}

.footer h3 {
  color: #ffffff;
  margin-bottom: 20px;
}

.footer-col-1,
.footer-col-2,
.footer-col-3,
.footer-col-4 {
  min-width: 250px;
  margin-bottom: 20px;
}

.footer-col-1 {
  flex-basis: 30%;
}

.footer-col-2 {
  flex: 1;
  text-align: center;
}

.footer-col-2 img {
  width: 180px;
  margin-bottom: 20px;
}

.footer-col-3,
.footer-col-4 {
  flex-basis: 12%;
  text-align: center;
}

ul {
  list-style-type: none;
}

.app-logo {
  margin-top: 20px;
}

.app-logo img {
  width: 140px;
}

.footer hr {
  border: none;
  background: #b5b5b5;
  height: 1px;
  margin: 20px 0;
}

.copyright {
  text-align: center;
}

.menu-icon {
  width: 28px;
  margin-left: 20px;
  display: none;
}

/* media query for menu */

@media only screen and (max-width: 800px) {
  nav ul {
    position: s;
    top: inherit;
    left: 0;
    background: transparent;
    ;
    width: 100%;
    /* overflow: hidden; */
    transition: max-height 0.5s;
  }

  i.eyelogin.far.fa-eye,
  i.eyelogin.fas.fa-eye-slash {
    position: absolute;
    cursor: pointer;
    right: 45px;
    top: 49.5% !important;
  }

  nav ul li {
    display: block;
    margin-right: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  /* nav ul li a {
      color: #000;
    } */
  .menu-icon {
    display: block;
    cursor: pointer;
  }
}

@media only screen and (max-width: 768px) {
  .search-box-input {
    display: none;
  }

  i.eyelogin.far.fa-eye,
  i.eyelogin.fas.fa-eye-slash {
    position: absolute;
    cursor: pointer;
    right: 45px;
    top: 49.5% !important;
  }
}

/* all products page */

.row-2 {
  /* justify-content: space-between; */
  margin: 97px auto 0px;
}

/* header.main_menu.single_page_menu {
      background: #010a0c;
      box-shadow: 0 0 15px rgb(240 233 233 / 79%);
  } */
select {
  border: 1px solid #00C9FF;
  padding: 5px;
}

select:focus {
  outline: none;
}

.page-btn {
  margin: 0 auto 80px;
}

.page-btn span {
  display: inline-block;
  border: 1px solid #00C9FF;
  margin-left: 10px;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  color: #fff;
}

.page-btn span:hover {
  background: #00C9FF;
  color: #ffffff;
}

/* single product details */

.single-product {
  margin-top: 80px;
}

.single-product .col-2 img {
  padding: 0;
}

.single-product .col-2 {
  padding: 20px;
}

.single-product h4 {
  margin: 20px 0;
  font-size: 22px;
  font-weight: bold;
}

.single-product select {
  display: block;
  padding: 10px;
  margin-top: 20px;
}

.single-product input {
  width: 50px;
  height: 40px;
  padding-left: 10px;
  font-size: 20px;
  margin-right: 10px;
  border: 1px solid #00C9FF;
}

input:focus {
  outline: none;
}

.single-product .fas {
  color: #00C9FF;
  margin-left: 10px;
}

.small-img-row {
  display: flex;
  justify-content: space-between;
}

.small-img-col {
  flex-basis: 24%;
  cursor: pointer;
}

/* cart items */

.cart-page {
  margin: 90px auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.cart-info {
  display: flex;
  flex-wrap: wrap;
}

th {
  text-align: left;
  padding: 5px;
  color: #ffffff;
  background: #1b2e4b;
  font-weight: normal;
}

td {
  padding: 10px 5px;
}

td input {
  width: 40px;
  height: 30px;
  padding: 5px;
}

td a {
  /* color: #00C9FF; */
  font-size: 12px;
}

td img {
  width: 80px;
  height: 80px;
  margin-right: 10px;
}

.total-price {
  display: flex;
  justify-content: flex-end;
}

.total-price table {
  border-top: 3px solid #00C9FF;
  width: 100%;
  max-width: 400px;
}

td:last-child {
  text-align: right;
}

th:last-child {
  text-align: right;
}

/* account page */
.account-page {
  padding: 50px 0;
  background: radial-gradient(#fff, #ffd6d6);
}

.form-container {
  background: #ffffff;
  width: 300px;
  height: 400px;
  position: relative;
  text-align: center;
  padding: 20px 0;
  margin: auto;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.form-container span {
  font-weight: bold;
  padding: 0 10px;
  color: #555555;
  cursor: pointer;
  width: 100px;
  display: inline-block;
}

.form-btn {
  display: inline-block;
}

.form-container form {
  max-width: 300px;
  padding: 0 20px;
  position: absolute;
  top: 130px;
  transition: transform 1s;
}

form input {
  width: 100%;
  height: 30px;
  margin: 10px 0;
  padding: 0 10px;
  border: 1px solid #ccc;
}

form .btn {
  width: 100%;
  border: none;
  cursor: pointer;
  margin: 10px 0;
}

form .btn:focus {
  outline: none;
}

#LoginForm {
  left: -300px;
}

#RegForm {
  left: 0;
}

form a {
  font-size: 12px;
}

#Indicator {
  width: 100px;
  border: none;
  background: #00C9FF;
  height: 3px;
  margin-top: 8px;
  transform: translateX(100px);
  transition: transform 1s;
}

.small-container h4 {
  font-size: 15px;
  margin-top: 10px;
  /* text-align: center; */
}

.form-check-label {
  margin-bottom: 0;
  font-size: 12px;
  color: #fff;
}

.sideMenu {
  position: absolute;
  left: -280px;
  background: #fff;
  width: 280px;
  height: 100vh;
  box-shadow: 0 0 10px #ddd;
  transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
}

.sideMenu.active {
  left: 0;
  transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  z-index: 99999;
}

.sideMenu ul {
  display: block;
  margin: 0;
  padding: 0;
}

.sideMenu ul li {
  display: block;
  border-top: 1px solid #ddd;
}

.sideMenu ul li:first-child {
  border: 0;
}

.sideMenu ul li a {
  display: block;
  padding: 20px 20px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
}

.menuIcon {
  display: inline-block;
  background: #000;
  padding: 3px 12px;
  cursor: pointer;
  position: absolute;
  right: -70px;
}

.menuIcon span {
  display: block;
  width: 25px;
  height: 4px;
  margin: 20px 0;
  background: #fff;
  position: relative;
  transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
}

.menuIcon span:before {
  position: absolute;
  content: "";
  left: 0;
  top: -10px;
  width: 25px;
  height: 4px;
  background: #fff;
  transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
}

.menuIcon span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -10px;
  width: 25px;
  height: 4px;
  background: #fff;
  transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
}

.menuIcon.active span {
  background: #000;
  transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
}

.menuIcon.active span:before {
  transform: rotate(45deg);
  top: 0px;
  transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
}

.menuIcon.active span:after {
  transform: rotate(-45deg);
  bottom: 0px;
  transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
}

.tablink {
  background-color: #555;
  color: white;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  font-size: 17px;
  width: 50%;
}

.tablink:hover {
  background-color: #777;
}

/* Style the tab content (and add height:100% for full page content) */
.tabcontent {
  color: white;
  display: none;
  padding: 100px 20px;
  height: 100%;
}

div#dropdown_id {
  left: 0px;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
  color: gray;
  font-family: Catamaran;
}

/* div#row-width {
      width: 33%!important;
  } */
ul.footer_links {
  display: flex;
  color: #fff;
}

ul.footer_links li a {
  margin: 0px 10px;
  color: #fff;
  font-weight: 200;
}

.btn-primary {
  color: #fff;
  background-color: #816e4e;
  border-color: #816e4e;
}

.form-check-input {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem;
}

input#exampleCheck1 {
  width: 15px;
  position: relative;
  padding: 0px;
  margin-right: 5px;
  top: -15px;

}

label#term_text {
  font-size: 15px;
}


.menu_fixed {
  position: fixed;
  z-index: 9999 !important;
  width: 100%;
  box-shadow: 0 0 15px rgb(29 29 29 / 72%);
  top: 0;
  background-color: #0b0f18a3 !important;
  display: none;
}

.navbar {
  padding: 0;
  background: transparent !important;
  /* background: #010a0c; */
  /* background:  #212529; */
  /* color: #212529; */
  border-radius: 6px;
}

.header-container {
  padding-top: 0px;
  padding-right: 16px;
  padding-left: 48px;
  background: #f6f6f7;
  border-bottom: 1px solid #cccccc8f;
  /* background: #060818; */
  z-index: 1030;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

input.form-control.authCss {
  background: #fff;
  color: #3b3f5c;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #6c757d !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #6c757d !important;
}

::placeholder {
  color: #6c757d !important;
}

select.form-control:not([size]):not([multiple]) {
  /* height: auto !important; */
}

ul.list {
  display: none;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.flex-row {
  /* -ms-flex-direction: row!important; */
  flex-direction: row !important;
}


a.btn.btn-primary.btn-browser {
  padding: 5px 8px;
  border-radius: 30px;
  position: absolute;
  bottom: -27px;
  left: 85px;
}

/* -----Market Place ki new css niche----------- */

.row {
  display: flex;
  /* align-items: center; */
  flex-wrap: wrap;
  /* justify-content: space-around; */
}

/* div#row-width {
  width: 35%!important;
} */

.justify-content {
  justify-content: space-around
}

.dropdown-menu:hover {
  opacity: 1 !important;
  visibility: visible !important;
}

@media (max-width:320px) {

  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100%;
    /* padding-right: 8px !important;
    padding-left: 8px !important; */
  }

  .navbar .toggle-sidebar,
  .navbar .sidebarCollapse {
    display: inline-block;
    position: relative;
    color: #000 !important;
    padding: 8px 0;
    font-size: 23px;
    line-height: 1.9;
    margin-left: 16px;
  }
}


@media (max-width:375px) {

  .navbar .toggle-sidebar,
  .navbar .sidebarCollapse {
    display: inline-block;
    position: relative;
    color: #000 !important;
    padding: 8px 0;
    font-size: 23px;
    line-height: 1.9;
    margin-left: 16px;
  }
}

/* =========sort date button colr==================== */
#dropdownMenuButtons {
  background: -webkit-linear-gradient(right, #816e4e 0%, #816e4e 51%, #816e4e 100%);
  border: 1px solid #816e4e;
  box-shadow: 0px 0px !important;
  transform: translateY(0px) !important;
  width: 100%;
  margin: 45px 0px 0px 0px;
  padding: 8px 5px !important;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

header.header.navbar.navbar-expand-sm.expand-header.innerHeader {
  height: 59px;
  margin-left: 30px;
  background: #f6f6f7 !important;
}

.header-shadow {
  box-shadow: 0 0 15px rgb(240 233 233 / 79%);
}

/* .dropdown-item.padding-profile{
  padding: 0px ;
} */
.market-label {
  color: #000;
}

.marketplace-image {
  height: 214px;
  object-fit: contain;
  /* width: 252px; */
  width: 100%;
}

header.header.navbar.navbar-expand-sm.innerHeader {
  height: 50px;
  background: #f6f6f7 !important;
  margin-left: 200px;
}

.react-confirm-alert-body>h1 {
  margin-top: 0;
  color: #000 !important;
}

.badge-count {
  background: #0a64b4;
  padding: 1px 5px;
  top: -8px;
  left: -1px;
  position: relative;
  border-radius: 50%;
}

.total-amount {
  text-align: end;
  margin-right: 180px;
  font-size: initial;
  color: #fff;
}

.cart-ul {
  padding: 15px !important
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* homepage banner */
.head-bg {
  background: #000;
  position: relative;
  padding: 10px;
  margin-top: 66px;
  margin-bottom: 50px;
}

.head-bg-img {
  position: absolute;
  left: 0;
  top: 0;
  background: url(http://demo.nrgthemes.com/projects/nrgnetwork/img/bg.jpg);
  height: 100%;
  width: 100%;
  background-size: cover;
  opacity: 0.2;
  z-index: 1;
  background-position: center center;
}

.head-bg-content {
  height: 400px;
  text-align: center;
  position: relative;
  z-index: 200;
  display: table-cell;
  vertical-align: middle;
  width: 5000px;
}

.head-bg-content h1 {
  font-size: 32px;
  line-height: 40px;
  color: #FFF !important;
  margin-bottom: 17px;
}

.head-bg-content p {
  color: #a1a4af;
  margin-bottom: 25px;
}

/* Recent activity section css */

.recent-card {
  border-color: rgb(250, 250, 250);
  border-width: 2px;
  border-radius: 12px;
  /* margin-bottom: 40px; */
  background-color: #fff;
  padding: 0px 35px;
  /* box-shadow: 0 6px 15px -4px rgb(0 0 0 / 20%); */
  transition: all 0.3s ease 0s;
  min-height: 311px;
  margin-left: 8px;
}

/* .recent-card:hover {
  border: 1px solid rgb(250 250 250 / 44%);
  cursor: pointer;
} */
.recent-list {
  overflow: hidden;
  position: relative;

}

.Recent-activity-section {
  padding: 80px 0px;
}

.recent-card svg {
  top: 12px;
  color: white;
  right: 12px;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, .7));
  opacity: 1;
  z-index: 10;
  position: absolute;
  text-shadow: 2px 2px #ff0000;
  transition-duration: 400ms;
}

.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.recent-card video {
  top: 0;
  left: 0;
  /* width: 177.77777778vh; */
  height: 282px;
  width: 100%;
  /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
  object-fit: cover;
  position: relative;
  /* z-index: 11111; */
  /* min-width: 100%; */
  /* transform: translate(-50%, -50%); */
  background-color: transparent;
  transition-duration: 400ms;
}

.bg-light-grey {
  padding: 8px;
  padding-bottom: 30px;
}

.bold-text {
  font-size: 18px;
  text-align: center;
  font-weight: 700;
}

.recent-card .heading {
  margin-bottom: 0px;
  height: 23px;
  /* padding-bottom: 20px; */
}

.recent-list img {
  height: 240px;
  object-fit: cover;
  width: 100%;
}
.recent-list:hover img {
  transform: scale(1.1);
  transition: .8s;
}

.bg-black {
  background-color: transparent;
}

.recent-card .bg-black p {
  color: #816e4e;
  padding: 2px 0;
}



.recent-card .bg-black {
  padding: 5px 0;
}

/*.recent-card .bg-black {
  padding: 5px 0;
}*/
.recent-card .bg-black {
  padding: 10px 0;
}

.bg-light-gray {
  background: transparent;
  /* padding: 56px 0px; */
}




.recent-card .heading p {
  font-size: 15px;
}

.recent-card p {
  margin-bottom: 0px;
  line-height: 1.5;
  font-size: 15px;
}

/* newsletter section css */
.newsletter {
  /* background: linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url(https://themes.getmotopress.com/emmet/wp-content/themes/emmet/images/main-bg.jpg); */
  /* background-image: url(http://w-cutechurch.torbara.com/wp-content/themes/cutechurch/images/dark-pattern.jpg); */
  background-position: center top;
  background-size: auto;
  background-color: transparent;
  background-repeat: repeat;
  background-attachment: fixed;
}

.newsletter-form h3 {
  color: #816e4e !important;
}

.newsletter-form p {
  color: #000 !important;
}

.newsletter-form {
  padding: 80px 0px;
}

.submit-block {
  border-radius: 6px;
  height: 50px;
  padding: 0px 20px;
}

.Recent-activity-section h2 {
  padding-bottom: 16px;
}

.subscribe-form .form-control {
  height: 50px;
  background: #fff;
  color: #000;
}

.pb-80 {
  padding-bottom: 80px !important
}

/* home page css */
/* .navbar-brand img {
  filter: brightness(0) invert(1);
} */
/* timer section css */
#timer .countdown-wrapper {
  margin: 0 auto;
}

#timer #countdown {
  /* font-family: 'Lato', sans-serif; */
  text-align: left;
  color: #eee;
  text-shadow: 1px 1px 5px black;

}

#timer .countdown-wrapper #countdown .timer {
  margin: 10px;
  padding: 3px 12px;
  font-size: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.bg-gold {
  background-color: #816e4e;
  box-shadow: 0 0 0 5px rgb(49 29 39) !important;
}

#timer .col-md-4.countdown-wrapper #countdown .timer {
  margin: 10px;
  padding: 20px;
  font-size: 35px;
  border-radius: 50%;
  cursor: pointer;
}

#timer .countdown-wrapper #countdown #hour {
  -webkit-box-shadow: 0 0 0 5px rgba(92, 184, 92, .5);
  -moz-box-shadow: 0 0 0 5px rgba(92, 184, 92, .5);
  box-shadow: 0 0 0 5px rgba(92, 184, 92, .5);
}

#timer .countdown-wrapper #countdown #hour:hover {
  -webkit-box-shadow: 0px 0px 15px 1px rgba(92, 184, 92, 1);
  -moz-box-shadow: 0px 0px 15px 1px rgba(92, 184, 92, 1);
  box-shadow: 0px 0px 15px 1px rgba(92, 184, 92, 1);
}

#timer .countdown-wrapper #countdown #min {
  -webkit-box-shadow: 0 0 0 5px rgba(91, 192, 222, .5);
  -moz-box-shadow: 0 0 0 5px rgba(91, 192, 222, .5);
  box-shadow: 0 0 0 5px rgba(91, 192, 222, .5);
}

#timer .countdown-wrapper #countdown #min:hover {
  -webkit-box-shadow: 0px 0px 15px 1px rgb(91, 192, 222);
  -moz-box-shadow: 0px 0px 15px 1px rgb(91, 192, 222);
  box-shadow: 0px 0px 15px 1px rgb(91, 192, 222);
}

#timer .countdown-wrapper #countdown #sec {
  -webkit-box-shadow: 0 0 0 5px rgba(2, 117, 216, .5);
  -moz-box-shadow: 0 0 0 5px rgba(2, 117, 216, .5);
  box-shadow: 0 0 0 5px rgba(2, 117, 216, .5)
}

#timer .countdown-wrapper #countdown #sec:hover {
  -webkit-box-shadow: 0px 0px 15px 1px rgba(2, 117, 216, 1);
  -moz-box-shadow: 0px 0px 15px 1px rgba(2, 117, 216, 1);
  box-shadow: 0px 0px 15px 1px rgba(2, 117, 216, 1);
}

#timer .countdown-wrapper .card .card-footer .btn {
  margin: 2px 0;
}

@media (min-width: 992px) and (max-width: 1199px) {
  #timer .countdown-wrapper #countdown .timer {
    margin: 10px;
    padding: 20px;
    font-size: 65px;
    border-radius: 50%;
    cursor: pointer;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #timer .countdown-wrapper #countdown .timer {
    margin: 10px;
    padding: 20px;
    font-size: 72px;
    border-radius: 50%;
    cursor: pointer;
  }

  #price_dollar {
    position: absolute;
    right: 80px;
    top: -450px;
  }
}

@media (max-width: 768px) {
  #timer .countdown-wrapper #countdown .timer {
    margin: 10px;
    padding: 20px;
    font-size: 73px;
    border-radius: 50%;
    cursor: pointer;
  }

  #price_dollar {
    position: absolute;
    right: 80px;
    top: -450px;
  }
}

@media (max-width: 767px) {

  #timer .countdown-wrapper #countdown #hour,
  #timer .countdown-wrapper #countdown #min,
  #timer .countdown-wrapper #countdown #sec {
    font-size: 60px;
    border-radius: 4%;
  }

}

@media (max-width: 576px) {

  #timer .countdown-wrapper #countdown #hour,
  #timer .countdown-wrapper #countdown #min,
  #timer .countdown-wrapper #countdown #sec {
    font-size: 25px;
    border-radius: 4%;
  }

  i.eyelogin.far.fa-eye,
  i.eyelogin.fas.fa-eye-slash {
    position: absolute;
    cursor: pointer;
    right: 45px;
    top: 46.5% !important;
  }

  #timer .countdown-wrapper #countdown .timer {
    padding: 13px;
  }
}

/* banner slider */
.slider-banner {
  margin-top: 77px;
}

.slider-banner video {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

.flip-countdown {
  text-align: left !important;
  perspective: 400px;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
  margin: 0 0.025em;
  font-size: 39px !important;
}

span.flip-countdown-title {
  display: none;
}

/* three image slider */
/* .alice-carousel__stage-item .item img {
  height: 362px;
  object-fit: cover;
  width: 100%;
  padding: 0px 5px 5px 5px;
} */
.alice-carousel__stage-item .item img {
  height: 400px;
  object-fit: fill;
  width: 100%;
  padding: 0px 5px 5px 5px;
}

/* .alice-carousel__stage-item .item img:hover{
-webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(0.9);
  } */
.alice-carousel {
  margin-top: 83px !important;
}

.alice-carousel__prev-btn-wrapper {
  display: none;
}

.alice-carousel__next-btn-wrapper {
  display: none;
}

.email-list .noty-cls a {
  color: #fff !important;
}

.Recent-activity-section #cdt .demo {
  position: absolute;
  right: 0;
  top: 0;
  display: none;
  z-index: 999;
}

/* countdown */
.Countdown {
  margin: 10px auto;
  padding-bottom: 20px;
  color: #816e4e;
}

.Countdown-col {
  display: inline-block;
}

.Countdown-col-element {
  display: inline-block;
  margin: 0 3px;
  display: flex;
  flex-direction: column;
}

.Countdown-col-element strong {
  font-size: 28px;
  text-align: right;
  height: 33px;
  line-height: 31px;
  padding-bottom: 0px;
  padding-right: 12px;
  border-right: 2px solid #816e4e;
}

.Countdown-col-element {
  text-align: center;
}

.Countdown {
  margin: 0px 0px 0px 0px;
  max-width: 274px;
  padding-bottom: 7px;
  margin-right: auto;
  margin-left: auto;
  padding-right: -10px;
  color: #816e4e;
  border-bottom: 1px solid #816e4e;
}

span.Countdown-col-element span {
  font-size: 8px;
  padding-right: 7px;
  letter-spacing: 0px;
  text-transform: uppercase;
}

span.current-date {
  color: #816e4e;
  font-size: 11px;
  font-weight: 700;
  margin-top: 9px !important;
}

a.btn_wishlist span {
  padding: 12px 0px !important;
  border: 1px solid #816e4e;
  text-align: center;
  margin: 20px 0px;
  width: 50%;
}

a.btn_buynow span {
  padding: 12px 0px !important;
  border: 1px solid #816e4e;
  text-align: center;
  margin: 20px 0px;
  width: 95.4%;
  background-color: #816e4e;
  ;
  color: #fff;

}

a.btn_buynow span:hover {

  background-color: #a77a2d;

}

div#price_dollar span {
  font-size: 26px;
  font-weight: bold;
}

#price_dollar {
  position: absolute;
  left: 290px;
  top: 258px;
  font-size: 18px;
  width: 500px;
}

.whiteclr {
  color: #fff !important;
}

#price_dollar1 {
  position: absolute;
  left: 290px;
  top: 257px;
  font-size: 18px;
  width: 500px;

}

.modal-dialog.modal-lg {
  margin: 100px auto;
  box-shadow: 0px 5px 20px #00000059;
}


div#price_dollar1 span {
  font-size: 26px;
  font-weight: bold;
}

.errorMsg11 {
  color: red !important;
}

i.eyeold.far.fa-eye,
i.eyeold.fas.fa-eye-slash {
  position: absolute;
  cursor: pointer;
  right: 35px;
  top: 25%;
}

i.eyenew.far.fa-eye,
i.eyenew.fas.fa-eye-slash {
  position: absolute;
  cursor: pointer;
  right: 35px;
  top: 45%;
}

i.eyeconfirm.far.fa-eye,
i.eyeconfirm.fas.fa-eye-slash {
  position: absolute;
  cursor: pointer;
  right: 35px;
  top: 65%;
}

i.eyelogin.far.fa-eye,
i.eyelogin.fas.fa-eye-slash {
  position: absolute;
  cursor: pointer;
  right: 45px;
  top: 45.5%;
}

i.eyelogin1.far.fa-eye,
i.eyelogin1.fas.fa-eye-slash {
  position: absolute;
  cursor: pointer;
  right: 45px;
  top: 33.5%;
}

i.eyelogin2.far.fa-eye,
i.eyelogin2.fas.fa-eye-slash {
  position: absolute;
  cursor: pointer;
  right: 45px;
  top: 47.5%;
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 14;
  position: absolute;
  top: 50%;
  display: block;
  width: 40px !important;
  height: 40px !important;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: #000000 !important;
  z-index: 999 !important;
  border-radius: 30px !important;
}

.slick-prev {
  left: 10px !important;
}

.slick-next {
  right: 10px !important;
}

ul.thumbs.animated {
  display: none;
}

span.days {
  font-weight: 900;
  margin: 0px 5px;
  color: #000;
}

.fa-clock:before {
  content: "\f017";
}


span.txt_metadata {
  font-size: 14px !important;
  font-weight: 200 !important;
  width: 45% !important;
  position: relative;
  bottom: 3px;
  height: 45px;
}

span.txt_metadata {
  margin: 0px !important;
}

span.txt {
  margin: 0px !important;
}

.bidtable>thead>tr>th {
  color: #d3d3d3;
  background: #413728;
  text-transform: capitalize;
}

.soldOut {
  position: absolute;
  z-index: 1;
}

.soldOut img {
  width: 129px;
}

.bp3-portal {
  z-index: 11111;
}

.react-datetime-picker.react-datetime-picker--closed.react-datetime-picker--enabled {
  width: 100%;
}

.video-react-controls-enabled.video-react-has-started.video-react-paused.video-react-fluid.video-react-user-inactive.video-react-workinghover.video-react {
  padding-top: 86% !important;
  margin-top: -24px !important;
}

.video-react-controls-enabled.video-react-paused.video-react-fluid.video-react-user-inactive.video-react-workinghover.video-react {
  padding-top: 86% !important;
  margin-top: -24px !important;
}

.video-react-controls-enabled.video-react-has-started.video-react-playing.video-react-waiting.video-react-fluid.video-react-user-active.video-react-workinghover.video-react {
  padding-top: 86% !important;
  margin-top: -24px !important;
}

.video-react-controls-enabled.video-react-has-started.video-react-paused.video-react-fluid.video-react-user-active.video-react-workinghover.video-react {
  padding-top: 86% !important;
  margin-top: -24px !important;
}

.video-react-controls-enabled.video-react-has-started.video-react-playing.video-react-fluid.video-react-user-active.video-react-workinghover.video-react {
  padding-top: 86% !important;
  margin-top: -24px !important;
}

.video-react-controls-enabled.video-react-paused.video-react-fluid.video-react-user-active.video-react-workinghover.video-react {
  padding-top: 86% !important;
  margin-top: -24px !important;
}

.video-react-controls-enabled.video-react-has-started.video-react-playing.video-react-waiting.video-react-fluid.video-react-user-inactive.video-react-workinghover.video-react {
  padding-top: 86% !important;
  margin-top: -24px !important;
}

.clockImg {
  height: 17px;
  width: 17px;
  display: inline-block;
  margin: -2px 3px 3px 3px;
}

.clockImg img {
  height: 100%;
  width: 100%;
  margin-bottom: 2px;
}

.market-count span {
  color: #000;
}

.react-datetime-picker__wrapper {
  border: none !important;
}

.offerprice input::placeholder {
  color: gray !important;
}

.offerprice input::placeholder {
  color: gray !important;
}
.error{
  color: red;
}
.d-toaster-modal .modal-content {
  border-top: 6px solid #dc3545;
}
.s-toaster-modal .modal-content {
  border-top: 6px solid #06A316;
}
.marketplace-image {
  height: 214px;
  object-fit: contain;
  /* width: 252px; */
  width: 100%;
}
.freeShip{
  color: grey !important;
}