.small-line {
    width: 30px;
    height: 4px;
    border-radius: 26px;
    /* opacity: 0.5; */
    background: var(--Logo-gradient, linear-gradient(90deg, rgba(249, 202, 63, 0.50) 0%, rgba(230, 38, 110, 0.50) 36.5%, rgba(109, 103, 167, 0.50) 70%, rgba(51, 171, 145, 0.50) 100%));
}
.drop-subheading {
    background: rgb(183 183 183 / 12%) !important;
    border-radius: 51px;
}
.drop-subheading {
    background: rgb(238 237 246) !important;
    border-radius: 51px;
    color: #000!important;
}
/* ------------- */
.blogImage {
    height: 480px;
    border-radius: 8px;
    margin-top: 15px;
    overflow: hidden;
}
.blogImage img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}
.blog-content {
    width: 50%;
    background: #fff;
    position: relative;
    box-shadow: 0 6px 15px -4px rgb(0 0 0 / 20%);
    border-radius: 12px;
    margin-top: -205px;
    /* padding: 20px; */
    margin-left: 35px;
}
.link-underline-dark {
    --bs-dark-rgb: 33, 37, 41;
    --bs-link-underline-opacity: 1;
    -webkit-text-decoration-color: rgba(var(--bs-dark-rgb),var(--bs-link-underline-opacity))!important;
    text-decoration-color: rgba(var(--bs-dark-rgb),var(--bs-link-underline-opacity))!important;
}
.readmore {
    border-radius: 0px;
    font-size: 15px;
    line-height: 1;
    border-bottom: 1px solid transparent;
}
.readmore:hover {
    border-bottom: 1px solid #000;
    border-radius: 0px;
    font-weight: 500;
}
/* -------------- */
@media only screen and (min-width: 991px) {
.blog-content {
    height: 288px;
}}
@media only screen and (max-width: 1199px) {
.blog-content {
    width: 80%;
}

}

@media only screen and (max-width: 767px) {
    .recent-card {
        margin-left: 0px;
    }
    .blogImage img {
        object-fit: cover;
    }
    .blog-content {
        width: 80%;
    }
    .blog-content .nft_world {
        font-size: 25px!important;
    }
  }
  @media only screen and (max-width: 500px) {
    
    .blog-card {
        padding: 1rem!important;
        /* box-shadow: 0 6px 15px -4px rgb(0 0 0 / 20%); */
        border: 1px solid #dee2e6!important;
        border-radius: 12px;
    }
    .blog-content {
        width: 100%;
        background: #fff;
        position: relative;
        box-shadow: none;
        padding: 16px 0px!important;
        border-radius: 12px;
        margin-top: 0px;
        padding-bottom: 0px!important;
        margin-left: 0px;
    }
    .blogImage {
        height: 290px;
        border-radius: 6px;
        position: relative;
        margin-top: 0px;
        overflow: hidden;
    }}