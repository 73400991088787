
.nftslider .slick-slide img{
    /* opacity: 0.8; */
    border-radius: 8px;
    transition: transform .5s; /* Animation */
}
.nftslider .sliderBox{
    border-radius: 8px;
    background: #fff;
    overflow: hidden;
    height: 237px;
    padding: 0px;
}
/* .nftslider .sliderBox.active {
    background: var(--Logo-gradient, linear-gradient(90deg, rgba(249, 202, 63, 0.50) 0%, rgba(230, 38, 110, 0.50) 36.5%, rgba(109, 103, 167, 0.50) 70%, rgba(51, 171, 145, 0.50) 100%));

} */


.nftslider .slick-slide .sliderBox:hover img{
    opacity: 1; 
    transform: scale(1.2);
}
.nftslider .slidercard{
    overflow: hidden;
    padding: 3px;
}
.nftslider .slidercard.active{
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 10px;
    box-shadow: inset 0px 33px 25px 0 rgba(249, 202, 63, 0.50), 
            inset 0 66px 15px 0px rgba(230, 38, 110, 0.50),
            inset 0 99px 5px 0px rgba(109, 103, 167, 0.50),
            inset 0 99px 5px 0px rgba(51, 171, 145, 0.50);
}
.nftslider .slick-slide:hover img{
    /* opacity: 1;
    transform: scale(1.2); */
}
/* ------------------- */

.sizeby select {
    background: rgb(183, 183, 183, 0.1);
    border-radius: 30px;
    padding: .375rem 20px;
    color: #000;
    height: 45px;
    font-weight: 600;
    border: none;
    appearance: none;
}
.sizeby select:focus {
    background: whitesmoke;
}
/* -------------- */
.innerFilter .dropdown .btn {
    background: rgb(183, 183, 183, 0.2);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.innerFilter .dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='8' viewBox='0 0 16 8' fill='none'%3E%3Cpath d='M0 0L8 8L16 0L8 3.2L0 0Z' fill='black'/%3E%3C/svg%3E");
    background-size: 12px;
}
.innerFilter .dropdown .dropdown-menu {
    margin-top: 10px;
    background-color: #FFF;
}
.innerFilter [type="radio"]:checked+label:before, .innerFilter [type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    border-radius: 50px;
    background: #fff;
}
.innerFilter [type="radio"]:checked+label:after, .innerFilter [type="radio"]:not(:checked)+label:after {
    content: '';
    width: 100%;
    height: 100%;
    background: var(--Logo-gradient, linear-gradient(90deg, rgba(249, 202, 63, 0.50) 0%, rgba(230, 38, 110, 0.50) 36.5%, rgba(109, 103, 167, 0.50) 70%, rgba(51, 171, 145, 0.50) 100%));
    box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.25) inset;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50px;
    border: 1px solid transparent;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.innerFilter [type="radio"]:checked+label, .innerFilter [type="radio"]:not(:checked)+label {
    position: relative;
    padding: 5px 15px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: rgba(0, 0, 0, 0.5);
    font-family: Catamaran;
    font-size: 20px;
    font-weight: 400;
}
.innerFilter .innerlabel {
    position: relative;
}
.innerFilter [type="radio"]:checked+label .innerlabel{
  color: #fff;
  z-index: 2;
}
.innerFilter .dropdown .dropdown-menu .dropdown-item {
    
    padding: 8px 10px !important;
    color: rgba(0, 0, 0) !important;
    border-bottom: none;
    text-transform: capitalize;
    font-family: Catamaran;
}
.innerFilter .dropdown .dropdown-menu .dropdown-item:last-child {
   border: none;
}
.css-1u9des2-indicatorSeparator {
    display: none;
}
.innerFilter input {
    font-size: 20px;
    text-align: center;
}
.resetBtn {
    background: #fff;
}
.resetBtn:hover {
    color: #fff;
    background: var(--Logo-gradient, linear-gradient(90deg, rgba(249, 202, 63, 0.50) 0%, rgba(230, 38, 110, 0.50) 36.5%, rgba(109, 103, 167, 0.50) 70%, rgba(51, 171, 145, 0.50) 100%));
    box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.25) inset;
}
.card.feature img {
    object-fit: contain;
}
.totalitem {
    opacity: 0.5;
}
.tag {
    background: #000000b8;
    display: flex;
    padding: 0px 8px;
    color: #fff;
    align-items: center;
    min-height: 22px;
    position: absolute;
    bottom: 4px;
    border-radius: 4px;
    right: 6px;
}
.tag p {
    font-size: 14px;
    line-height: 1.5;
}
/* --------------- */
.tag-center {
    position: absolute;
    top: 106px;
    bottom: 0;
    background: rgb(255, 255, 255, 0.60);
    padding: 20px 20px 20px 20px;
    overflow: hidden;
    display: inline-block;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    left: 6px;
    font-weight: 500;
    display: flex;
    margin: 0 auto;
    font-size: 15px;
    right: 0;
    border: none;
    border-radius: 4px;
    width: 100px;
    justify-content: center;
    height: 23px;
    align-items: center;
}
/* .tag-center {
    position: absolute;
    top: 14px;
    left: 0;
    bottom: 0;
    background: #000000bd;
    padding: 11px 26px 23px 26px;
    overflow: hidden;
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 0%);
    -webkit-backdrop-filter: blur(10px);
    left: -29px;
    color: #Fff;
    margin: 0 auto;
    text-transform: uppercase;
    font-size: 14px;
    transform: rotate(-45deg);
    border: none;
    border-radius: 9px;
    width: 130px;
    justify-content: center;
    height: 24px;
    font-family: 'Catamaran';
    align-items: center;
} */
.rs-dropdown-menu {
    position: absolute;
    width: 93%;
    top: 48px;
    text-align: left;
    z-index: 111;
    background: #f7f7f7;
    box-shadow: none!important;
}
.rs-dropdown-menu:focus{
    outline: none;
    box-shadow: none;
}
.rs-btn-primary {
    border: none;
    height: 46px;
    background: transparent;
    width: 46px;
    margin-bottom: 20px;
    border-radius: 100px;
}


/* ------------------- */
@media only screen and (max-width: 767px) {
    .searchbox {
        margin-left: 10px;
    }
    .filter_desktop{
        display: none;
    }
    .tag {
        margin-left: 6px;
    }
    
    
  }

  @media only screen and (max-width: 575px) {
  .searchbox,.filterbtn {
    margin-bottom: 10px;
}
.filter_mobile .sizeby {
    justify-content: end;
}
}