 
.h-line{
    border-top: 1px solid #000000;
    opacity: 0.5;
}

.box-shadow-none {
    box-shadow: none!important;
}
.fw-400{
    font-weight: 400!important;
}
.navbar-logo {
    width: 200px;
}
/* ------Banner Start------- */
.custom-arrow {
    position: absolute;
    top: 50%;
    fill: rgba(255, 255, 255, 0.30);
    opacity: 0.5;
    /* background: rgba(0, 0, 0, 0.5); */
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    z-index: 2;
    height: 50px;
    width: 50px;
    padding: 10px 0px;
    border-radius: 50%;
    transform: translateY(-50%);
}
.carousel .slide .legend {
    position: absolute;
    text-align: left;
    bottom: 0;
    left: 0;
    margin-left: 0;
    width: 100%;
    border-radius: 0px;
    background: rgba(255, 255, 255, 0.70);
    backdrop-filter: blur(6.449999809265137px);
    opacity: 1;
    color: #000;
    font-weight: 700;
   
}
.carousel .slide:hover{
    cursor: pointer;
}
.carousel .slide .legend {
    transition: 0.5s;
    transform: translateY(100%);
}
.carousel .slide:hover .legend {
    transform: translateY(0);
}
.carousel .slide .legend h5 {
    font-family: Orbitron;
    font-size: 24px;
    font-weight: 700;
}
.carousel .slide {
    min-width: 100%;
    margin: 0;
    height: 658px;
    position: relative;
    text-align: center;
}
.carousel .slide img {
    height: 658px;
    object-fit: cover;
    object-position: center;
}
.carousel .slide .legend h6 {
    font-family: Orbitron;
    font-size: 17.025px;
    font-weight: 700;
}
.carousel .slide .legend p {
    color: #000;
    font-size: 17.025px;
    opacity: 0.5;
    margin-bottom: 0px;
    font-weight: 500;
}
.mintbtn {
    border-radius: 100px;
    background: var(--White, #FFF);
    color: #101111;
    font-weight: 600;
    padding: 13px 32px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.mintbtn:hover {
    border-radius: 100px;
    background: linear-gradient(90deg, rgba(249, 202, 63, 0.50) 0%, rgba(230, 38, 110, 0.50) 36.5%, rgba(109, 103, 167, 0.50) 70%, rgba(51, 171, 145, 0.50) 100%);
    color: #fff;
}
.world .slick-prev, .world .slick-next {
   display: none!important;
}
  .prev-arrow {
    left: 15px; /* Adjust position for left arrow */
  }
  
  .next-arrow {
    right: 15px; /* Adjust position for right arrow */
  }
  
  .custom-arrow:hover {
    background: rgba(255, 255, 255, 0.30);
  }

  /* -------------- */
  .collecting_art {
    min-height: 549px;
}


.video_art video {
    position: absolute;
    top: 0;
    bottom: 0;
    object-fit: cover;
    left: 0;
    opacity: 40%;
    right: 0;
    width: 100%;
    height: 100%;
}
.collect-card:hover {
    border-radius: 12px;
    padding: 40px 26px 10px 26px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    margin: auto;
    width: 277px;
    min-height: 373.348px;
    max-height: 373.348px;
}
.feature_collection a {
    text-decoration: none!important;
    color: #101111!important;
}
.collect-card {
    border-radius: 12px;
    background: rgb(255, 255, 255, 0.40);
    padding: 26px 26px 26px 26px;
    overflow: hidden;
    display: inline-block;
    box-shadow: 0px 0px 20px 0px rgba(143, 143, 143, 0.25);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    text-align: center;
    margin: auto;
    width: 277px;
    min-height: 72px;
    transition: 0.7s;
    max-height: 72px;
}

.collect-card img{
    width: 145px;
    height: 145px;
    opacity: 40%;
}
.collect-card p {
    color: #000;
    opacity: 0.5;
    font-weight: 400;
}
.btn-circle {
    height: 65px;
    border-radius: 50%;
    border: none;
    width: 65px;
    background: rgba(183, 183, 183, 0.15);
    font-weight: 600;
}
.feature_artworks .card.feature:hover .btn-circle {
    background: #fff;
}
.feature_artworks .card.feature .btn-circle:hover {
    color: #fff;
    background: linear-gradient(90deg, rgba(249, 202, 63, 0.50) 0%, rgba(230, 38, 110, 0.50) 36.5%, rgba(109, 103, 167, 0.50) 70%, rgba(51, 171, 145, 0.50) 100%);
}

.feature_artworks .card.feature:hover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 8px;
    background: var(--Logo-gradient, linear-gradient(90deg, rgba(249, 202, 63, 0.50) 0%, rgba(230, 38, 110, 0.50) 36.5%, rgba(109, 103, 167, 0.50) 70%, rgba(51, 171, 145, 0.50) 100%));
    opacity: 0.3;
}
.card.feature1 .card-text {
    color: #101111;
    font-family: Catamaran;
    font-size: 20px;
    opacity: 0.3;
    font-weight: 600;
}
.card.feature1:hover .card-text {
    color: #101111;
    opacity: 1;
}

/* ----------Tabs style2-------------- */

.tab_style .nav-pills .nav-link.active, .tab_style .nav-pills .show>.nav-link {
    border-radius: 16px;
    background: var(--White, #FFF);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}
.tab_style .nav-pills .nav-link {
    border-radius: .25rem;
    width: 242px;
    height: 81px;
    padding: 30px 10px;
    color: #000;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
}
.tab_style .nav-item {
    margin-bottom: 20px;
}
/* ---------Blog Start----------- */
.blogImg img {
    border-radius: 8px;
    height: 425px;
    width: 100%;
    object-fit: cover;
}
.tab_style3 .nav-pills .nav-link.active, .tab_style3 .nav-pills .show>.nav-link {
    color: #000;
    background-color: transparent;
}

.tab_style3 .nav-item:hover{
    opacity: 1;
}
.tab_style3 .nav-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    opacity: 0.5;
}
.tab_style3 .nav-item:last-child {
    border-bottom: none;
}
.tab_style3 .nav-link {
    padding: 20px 0rem;
}
.content-box {
    min-height: 207px;
}
.subheader {
    background-image: url(../../../../public/images/mint.png);
    background-color: rgb(183, 183, 183, 0.1);
    background-position: center;
}
@media only screen and (min-width: 1200px) {
.column-custom {
    padding-right: 14.625px;
    padding-left: 14.625px;
}}
@media only screen and (max-width: 1200px) {
.free_mint .mintbtn {
    margin-top: -30px;
}
}
@media only screen and (max-width: 991px) {
    .tab_style .nav-pills .nav-link {
        padding: 18px 20px;
        /* font-size: 11px; */
        height: auto;
        margin-right: 10px;
        width: auto;
    }
    .footer_part .single_footer_part .social_icon {
        margin-top: 3%;
    }
}
@media only screen and (max-width: 944px) {
.collect-card {
    min-height: 355.348px;
    max-height: 355.348px;
}}
@media only screen and (max-width: 767px) {
    .tab_style .nav-pills .nav-link {
        padding: 18px 18px;
        font-size: 16px;
        height: auto;
        width: auto;
    }
}
@media only screen and (max-width: 533px) {
    .tab_style .nav-pills .nav-link {
        padding: 15px 15px;
        font-size: 15px;
        height: auto;
        width: auto;
    }
}
@media only screen and (max-width: 500px) {
    .navbar-logo {
        width: 186px;
    }
    .panel-default>.panel-heading a {
        padding: 12px 0px;
    }
    .custom-arrow {
        font-size: 17px;
        height: 40px;
        width: 40px;
    }
    .carousel .slide {
        min-width: 100%;
        margin: 0;
        height: 460px;
        position: relative;
        text-align: center;
    }
    .carousel .slide img{
        min-width: 100%;
        margin: 0;
        height: 460px;
        object-fit: cover;
        position: relative;
        text-align: center;
    }
    .banner .mintbtn {
        padding: 4px 15px;
        margin-top: 9px;
    }
    .carousel .slide .legend h5 {
        font-size: 16px;
    }
    .carousel .slide .legend h6, .carousel .slide .legend p {
        font-size: 14px;
    }
    .collect-card {
        min-height: 355.348px;
        max-height: 355.348px;
    }
    .collect-card:hover {
        min-height: 355.348px;
        max-height: 355.348px;
    }
    .feature_artworks,.feature_collection,.world,.blog,.free_mint  {
        padding-bottom: 0px!important;
    }
    .tophead {
        font-size: 20px;
    }
  
    .tab_style .nav-pills .nav-link {
        border-radius: .25rem;
        width: auto;
        height: auto;
        padding: 12px 12px;
        color: #000;
        text-align: center;
        font-weight: 600;
        font-size: 14px;
    }
  
    .free_mint .mintbtn {
        margin-top: 0px;
        /* height: 32px; */
        padding: 8px 15px;
    }
    
    .free_mint .nav-pills {
        justify-content: space-between;
    }
    .bg-mint {
        padding: 22px 22px!important;
    }
    }
    @media only screen and (max-width:  382px) {
        .tab_style .nav-pills .nav-link {
            padding: 11px 7px;
            font-size: 13px;
        }
    }
   
    @media only screen and (max-width: 360px) {
    .tophead {
        font-size: 20px;
    }
    .topheadpera {
        font-size: 14px;
    }}
    @media only screen and (max-width:  335px) {
        .tab_style .nav-pills .nav-link {
            padding: 11px 7px;
            font-size: 11px;
        }
    }